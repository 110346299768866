import React from 'react';
import './App.css';
import logo from './logo.png';  // Updated to import the new logo.png

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />  {/* Using the new logo.png */}
        <h1>Welcome to FinboCloud</h1>
        <p>Your trusted partner in DevOps and Cloud Solutions</p>
        <p className="star-rating">★★★★★</p> {/* Add a class to style the stars */}
      </header>
  

      <section className="App-contact">
  <h2>Contact Us</h2>
  <p>
    <i className="fas fa-phone"></i> Phone: <a href="tel:+447312458552">+447312458552</a> | 
    <i className="fab fa-whatsapp"></i> <a href="https://wa.me/447312458552" target="_blank" rel="noopener noreferrer">WhatsApp</a>
  </p>
  <p>
    <i className="fas fa-envelope"></i> Email: <a href="mailto:admin@finbocloud.co.uk">admin@finbocloud.co.uk</a>
  </p>
</section>



      <section className="App-about">
        <h2>About Us</h2>
        <p>At Finbocloud, we are dedicated to empowering organizations to achieve their full potential through innovative cloud technologies and DevOps practices. Our mission is to provide seamless, efficient, and secure cloud migration and management services that drive business growth and operational excellence.</p>
      </section>

      <section className="App-services">
        <h2>Our Services</h2>
        <ul>
          <li>Infrastructure as Code</li>
          <li>CI/CD Pipeline Development</li>
          <li>DevOps and DevSecOps Services</li>
          <li>Application Development and Hosting</li>
          <li>Cloud Infrastructure Management</li>
        </ul>
      </section>

      <section className="App-goal">
        <h2>Our Goal and Mission Statement</h2>
        <p>At Finbocloud, our goal is to be the leading provider of DevOps and cloud solutions, helping organizations navigate the complexities of digital transformation. Our mission is to:</p>
        <ul>
          <li>Empower businesses with cutting-edge cloud technologies and DevOps practices.</li>
          <li>Deliver customized solutions that enhance operational efficiency and drive innovation.</li>
          <li>Ensure seamless cloud migrations with minimal disruption to business operations.</li>
          <li>Provide ongoing support and management to maintain optimal performance and security.</li>
        </ul>
      </section>

      <section className="App-how-we-help">
        <h2>How We Help Organizations Rapidly Ascend and Migrate to the Cloud</h2>
        <p>We understand that migrating to the cloud can be a daunting task. That’s why we offer a comprehensive suite of services designed to make the transition as smooth and efficient as possible:</p>
        <ul>
          <li><strong>Infrastructure as Code:</strong> Automating the provisioning and management of cloud infrastructure to ensure consistency and scalability.</li>
          <li><strong>CI/CD Pipeline Development:</strong> Streamlining the software development process with continuous integration and continuous delivery pipelines.</li>
          <li><strong>DevOps and DevSecOps Services:</strong> Implementing best practices to enhance collaboration, security, and efficiency across development and operations teams.</li>
          <li><strong>Application Development and Hosting:</strong> Building and hosting applications that are optimized for the cloud environment.</li>
          <li><strong>Cloud Infrastructure Management:</strong> Providing ongoing management and support to ensure your cloud infrastructure remains secure, efficient, and cost-effective.</li>
        </ul>
      </section>

      <section className="App-experiences">
        <h2>Customer Experiences</h2>
        <div className="App-review">
          <p>“Finbocloud transformed our IT infrastructure, making it more scalable and secure. Their expertise in DevOps and cloud solutions is unmatched.”</p>
          <p><strong>- Jane Doe, CTO of Tech Innovators</strong></p>
        </div>
        <div className="App-review">
          <p>“The team at Finbocloud guided us through a complex cloud migration with ease. Their support and knowledge were invaluable.”</p>
          <p><strong>- John Smith, CEO of Future Enterprises</strong></p>
        </div>
      </section>

      <section className="App-reviews">
        <h2>Customer Reviews
        <p className="star-rating">★★★★★</p> {/* Add a class to style the stars */}
        </h2>
        <div className="App-review">
          <p>“Finbocloud’s services have been a game-changer for our business. Their proactive approach and attention to detail ensured a smooth transition to the cloud.”</p>
          <p><strong>- Sarah Lee, Operations Manager at Global Solutions</strong></p>
        </div>
        <div className="App-review">
          <p>“We were impressed by Finbocloud’s professionalism and technical expertise. They helped us optimize our cloud infrastructure, resulting in significant cost savings.”</p>
          <p><strong>- Michael Brown, IT Director at Innovative Systems</strong></p>
        </div>
      </section>

      <footer className="App-footer">
  <h2>Contact Us</h2>
  <p>
    <i className="fas fa-phone"></i> Phone: <a href="tel:+447312458552">+447312458552</a> | 
    <i className="fab fa-whatsapp"></i> <a href="https://wa.me/447312458552" target="_blank" rel="noopener noreferrer">WhatsApp</a>
  </p>
  <p>
    <i className="fas fa-envelope"></i> Email: <a href="mailto:admin@finbocloud.co.uk">admin@finbocloud.co.uk</a>
  </p>
  <p className="star-rating">★★★★★</p> {/* Add a class to style the stars */}
<p className="footer-copy">© <span className="company-name">Finbocloud</span></p> {/* Add classes for styling */}

</footer>
    </div>
  );
}

export default App;
